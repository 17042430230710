/**
* Generated automatically at built-time (2025-01-02T06:47:20.271Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "vinskap-black-friday",templateKey: "sites/87-0744316a-241c-46c0-9f9b-b69762d1c763"};